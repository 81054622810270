<template>
  <b-modal
    id="select-design"
    footer-class="border-top-0"
    no-close-on-backdrop
    no-close-on-esc
    centered
    visible
    style="width: 300px"
    size="sm"
    hide-footer
    :hide-header-close="generateCodeRequestLoading || change2FAStatusLoading"
    :visible="configure2FASelectedModal"
    @hidden="configure2FASelectedModal = false"
  >
    <template #default="{ hide }">
      <div class="w-100">
        <div class="" style="margin: 5px">
          <b-row>
            <b-col>
              <b-card
                class="shadow-none d-flex justify-content-center align-items-center"
              >
                <b-card-body class="p-0 d-flex flex-column align-items-center">
                  <section
                    class="mt-1 text-center font-weight-bold font-small-3"
                    style="opacity: 0.8"
                    v-if="selectedOption === 'EMAIL'"
                  >
                    <p class="m-0 mb-1">
                      {{ $t("email_verification_question") }}
                    </p>
                    <p class="my-0" style="opacity: 0.7">
                      {{ $t("email_verification_info") }}
                    </p>
                  </section>

                  <section
                    class="text-center font-weight-bold font-small-3"
                    style="opacity: 0.8"
                    v-if="selectedOption === 'APP'"
                  >
                    <p class="m-0 mb-1">
                      {{ $t("app_verification_question") }}
                    </p>

                    <div>
                      <b-spinner
                        small
                        v-if="APP_2FA.loadingImage"
                        class="text-center"
                      ></b-spinner>
                      <b-img
                        v-else
                        contain
                        height="150px"
                        :src="APP_2FA.imgQRPath"
                      >
                      </b-img>
                    </div>

                    <p class="my-0" style="opacity: 0.7">
                      {{ $t("app_verification_info") }}
                    </p>
                  </section>

                  <section
                    class="text-center font-weight-bold font-small-3"
                    style="opacity: 0.8"
                    v-if="selectedOption === 'NONE'"
                  >
                    <p class="m-0 mb-1">
                      {{ $t("disable_2FA_question") }}
                    </p>
                  </section>

                  <section class="mt-2 mb-1 w-100" style="flex: 1">
                    <b-button
                      @click="handleSet2FAStatus()"
                      class="w-100"
                      variant="primary"
                      :disabled="
                        generateCodeRequestLoading || change2FAStatusLoading
                      "
                    >
                      <span
                        v-if="change2FAStatusLoading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      {{ $t("buttons.run") }}</b-button
                    >
                  </section>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { BModal } from "bootstrap-vue";
import { mapActions, mapMutations, mapState } from "vuex";
import { translatableText } from "@core/utils/utils";
import { axiosErrorHandle } from "@core/utils/errorHandler";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { SET_USER_DATA } from "@/auth/utils";

export default {
  name: "Configure2FASelected",
  components: { BModal },
  data() {
    return {
      configure2FASelectedModal: false,
      selectedOption: null,
      payload: null,
      APP_2FA: {
        imgQRPath: "",
        loadingImage: false,
      },
    };
  },
  computed: {
    ...mapState("verification", [
      "generateCodeRequestLoading",
      "change2FAStatusLoading",
    ]),
  },
  methods: {
    ...mapMutations("verification", ["SET_SELECT_2FA_VERIFICATION_MODAL"]),
    ...mapActions("verification", ["generate2FASecretApp", "set2FAStatus"]),
    async showModal(selectedOption, payload) {
      this.configure2FASelectedModal = true;
      this.selectedOption = selectedOption;
      this.payload = payload;
      if (selectedOption === "APP") await this.handleGenerate2FASecretApp();
    },
    async handleGenerate2FASecretApp() {
      try {
        this.APP_2FA.loadingImage = true;
        const secretApp = await this.generate2FASecretApp();
        if (secretApp) {
          this.APP_2FA.imgQRPath = secretApp.code;
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.APP_2FA.loadingImage = false;
      }
    },

    async handleSet2FAStatus() {
      try {
        const userData = JSON.parse(localStorage.getItem("userData"));
        const changeStatus = await this.set2FAStatus({
          ...this.payload,
          userId: userData._id,
        });
        if (changeStatus) {
          this.notify(
            this.$t("2FA"),
            this.$t("account_security_changed"),
            "success"
          );
          SET_USER_DATA({
            ...userData,
            is2FAEnabled: this.payload.isEnable,
            type2FA: this.payload.type,
          });
          this.configure2FASelectedModal = false;
          this.$emit("configured");
        }
      } catch (e) {
        this.notify(
          this.$t("2FA"),
          await translatableText({ text: axiosErrorHandle(e) }),
          "danger"
        );
      }
    },

    notify(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t(title),
          icon: "LockIcon",
          text: text.toString(),
          variant: variant,
        },
      });
    },
  },
};
</script>

<style scoped></style>
