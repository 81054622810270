<template>
  <div>
    <b-modal
      id="select-design"
      footer-class="border-top-0"
      no-close-on-backdrop
      no-close-on-esc
      centered
      visible
      style="width: 300px"
      size="md"
      hide-footer
      :visible="select2FAOptionModal"
      @hidden="select2FAOptionModal = false"
    >
      <template #default="{ hide }">
        <div class="w-100">
          <div class="" style="margin: 5px">
            <b-row>
              <b-col>
                <b-card
                  @click="active = 1"
                  :class="{ 'border-primary': active === 1 }"
                  class="d-flex justify-content-center align-items-center"
                >
                  <b-card-body
                    class="p-0 d-flex flex-column align-items-center"
                  >
                    <section>
                      <img
                        alt="email_auth"
                        :src="require('@/assets/images/png/email.png')"
                        style="max-height: 60px; max-width: 60px"
                      />
                    </section>

                    <section
                      class="mt-1 text-center font-weight-bold font-small-3"
                      style="opacity: 0.8"
                    >
                      <p class="m-0">2FA</p>
                      <p class="my-0" style="opacity: 0.7">
                        {{ $t("email_verification") }}
                      </p>
                    </section>

                    <section class="mt-1 font-small-3">
                      {{ $t("email_verification_text") }}
                    </section>

                    <section class="mt-2 mb-1 w-100" style="flex: 1">
                      <b-button
                        :disabled="active !== 1"
                        class="w-100"
                        :class="{ 'pointer-events-none': active !== 1 }"
                        variant="primary"
                        @click="handleSelectedOption('EMAIL')"
                        >{{ $t("buttons.accept") }}</b-button
                      >

                      <b-button
                        @click="handleSelectedOption('NONE')"
                        size="sm"
                        class="mt-1 w-100"
                        :disabled="active !== 1"
                        :class="{ 'pointer-events-none': active !== 1 }"
                        v-if="current2FASelectedOption === 1"
                        variant="secondary"
                      >
                        {{ $t("buttons.deactivate") }}
                      </b-button>
                    </section>
                  </b-card-body>
                </b-card>
              </b-col>

              <b-col>
                <b-card
                  class="d-flex justify-content-center align-items-center"
                  :class="{ 'border-primary': active === 2 }"
                  @click="active = 2"
                >
                  <b-card-body
                    class="p-0 d-flex flex-column align-items-center"
                  >
                    <section>
                      <img
                        alt="email_auth"
                        :src="require('@/assets/images/png/locked.png')"
                        style="max-height: 60px; max-width: 60px"
                      />
                    </section>

                    <section
                      class="mt-1 text-center font-weight-bold font-small-3"
                      style="opacity: 0.8"
                    >
                      <p class="m-0">2FA</p>
                      <p class="my-0" style="opacity: 0.7">
                        {{ $t("app_verification") }}
                      </p>
                    </section>

                    <section class="mt-1 font-small-3">
                      {{ $t("app_verification_text") }}
                    </section>

                    <section class="mt-2 mb-1 w-100" style="flex: 1">
                      <b-button
                        :disabled="active !== 2"
                        class="w-100"
                        :class="{ 'pointer-events-none': active !== 2 }"
                        variant="primary"
                        @click="handleSelectedOption('APP')"
                        >{{ $t("buttons.accept") }}</b-button
                      >

                      <b-button
                        @click="handleSelectedOption('NONE')"
                        size="sm"
                        class="mt-1 w-100"
                        :disabled="active !== 2"
                        :class="{ 'pointer-events-none': active !== 2 }"
                        v-if="current2FASelectedOption === 2"
                        variant="secondary"
                      >
                        {{ $t("buttons.deactivate") }}
                      </b-button>
                    </section>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
          </div>
        </div>
      </template>
    </b-modal>

    <Configure2FASelected
      ref="configure2FASelected"
      @configured="handleConfigured"
    />
  </div>
</template>

<script>
import { BModal } from "bootstrap-vue";
import Configure2FASelected from "@/views/pages/account-setting/2FA/Configure2FASelected.vue";
import { getUserData } from "@/auth/utils";

export default {
  name: "Select2FAOption",
  components: { Configure2FASelected, BModal },
  data() {
    return {
      select2FAOptionModal: false,
      active: 1,
      current2FASelectedOption: 0,
      payload: {
        EMAIL: { isEnable: true, type: "EMAIL" },
        APP: { isEnable: true, type: "APP" },
        NONE: { isEnable: false, type: "NONE" },
      },
    };
  },
  methods: {
    resetValues() {
      this.active = 0;
      this.current2FASelectedOption = 0;
    },
    showModal() {
      this.select2FAOptionModal = true;
      if (getUserData().is2FAEnabled) {
        if (getUserData().type2FA === "EMAIL") {
          this.active = 1;
          this.current2FASelectedOption = 1;
        } else if (getUserData().type2FA === "APP") {
          this.active = 2;
          this.current2FASelectedOption = 2;
        }
      } else {
        this.active = 0;
      }
    },
    handleSelectedOption(option) {
      const payload = this.payload[option];
      this.$refs.configure2FASelected.showModal(option, payload);
    },
    handleConfigured() {
      this.select2FAOptionModal = false;
      this.resetValues()
    },
  },
};
</script>

<style scoped></style>
