<template>
  <b-row>
    <b-col xl="12">
      <b-card class="d-flex">
        <b-card-title class="font-medium-2" style="opacity: 0.8"
          >{{$t('2FA')}}</b-card-title
        >
        <section class="pb-1">
          <div class="font-weight-bold font-medium-2">
            {{
              is2FAActivated() === true ? $t("2FAEnabled") : $t("2FADisabled")
            }}
          </div>
        </section>
        <section class="pb-1">
          <div class="font-weight-bold font-small-4" style="opacity: 0.5">
            {{$t('2FA_info')}}
          </div>
        </section>
        <section class="py-1">
          <b-button @click="handleClick" variant="outline-primary">
            {{ $t("labels.select") }}
          </b-button>
        </section>
      </b-card>
    </b-col>

    <Select2FAOption ref="select2FAOption" />
  </b-row>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import Select2FAOption from "@/views/pages/account-setting/2FA/Select2FAOption.vue";
import { getUserData } from "@/auth/utils";

export default {
  name: "AccountSettingSecurity",
  components: {
    Select2FAOption,
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      //optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      profileFile: null,
    };
  },

  setup() {
    const select2FAOption = ref(null);

    const handleClick = () => {
      select2FAOption.value.showModal();
    };

    const is2FAActivated = () => {
      return getUserData().is2FAEnabled;
    };

    return {
      handleClick,
      is2FAActivated,
      select2FAOption,
    };
  },
};
</script>

<style ></style>
